<template>
  <v-container>
    <v-stepper v-model="numeroScheda" non-linear>
      <v-stepper-header>
        <v-stepper-step editable :complete="numeroScheda > 1" step="1"
          >Dati generali</v-stepper-step
        >
        <v-divider></v-divider>
        <v-stepper-step editable :complete="numeroScheda > 2" step="2"
          >Rilevazioni</v-stepper-step
        >
        <v-divider></v-divider>
        <v-stepper-step editable :complete="numeroScheda > 3" step="3"
          >Esito</v-stepper-step
        >
        <v-divider></v-divider>
        <v-stepper-step editable :complete="numeroScheda > 4" step="4"
          >Firme</v-stepper-step
        >
      </v-stepper-header>
      <v-form v-model="form" ref="validForm">
        <v-stepper-items>
          <!--Prima scheda - Dati generali-->
          <v-stepper-content step="1">
            <v-flex d-flex xs12 sm12 md12 lg12 xl12>
              <v-col xs6 sm6 md6 lg6 xl6>
                <DataPicker
                  label="Data verbale"
                  v-model="verbale.dataVerifica"
                  id="dataVerbale"
                ></DataPicker>
              </v-col>
              <v-col xs6 sm6 md6 lg6 xl6>
                <v-select
                  label="Verificatore"
                  outlined
                  :items="listaVerificatori"
                  item-text="name"
                  item-value="ad_user_id"
                  v-model="verbale.ad_userverificatore_id"
                  no-data-text
                  :rules="[rules.select]"
                  id="verificatore"
                ></v-select>
              </v-col>
            </v-flex>
            <v-flex d-flex xs12 sm12 md12 lg12 xl12>
              <v-col xs6 sm6 md6 lg6 xl6>
                <v-select
                  :items="tipologiaMacchinario"
                  outlined
                  label="Tipo Attrezzatura"
                  no-data-text="Nessun dato"
                  v-model="verbale.tipologiaMacchinario"
                  item-text="name"
                  item-value="id"
                  id="tipologiaMacchinario"
                  :rules="[rules.select]"
                ></v-select>
              </v-col>
              <!--<v-col xs6 sm6 md6 lg6 xl6 v-if="mostraTipoApparecchio">
                <v-text-field
                  label="Tipo Apparecchio"
                  outlined
                  v-model="verbale.altroTipoMacchinario"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>-->
            </v-flex>
            <v-flex d-flex xs12 sm12 md12 lg12 xl12>
              <v-col xs6 sm6 md6 lg6 xl6>
                <v-text-field
                  outlined
                  label="Tipo"
                  v-model="verbale.tipoMacchinario"
                  :rules="[rules.required]"
                  id="tipoMacchinario"
                ></v-text-field>
              </v-col>
              <v-col xs2 sm2 md2 lg2 xl2>
                <v-text-field
                  outlined
                  label="Matricola"
                  v-model="verbale.matricola"
                  id="matricola"
                ></v-text-field>
              </v-col>
              <v-col xs2 sm2 md2 lg2 xl2>
                <v-text-field
                  outlined
                  label="Anno"
                  v-model="verbale.anno"
                  :rules="[rules.required]"
                  id="anno"
                ></v-text-field>
              </v-col>
              <v-col xs2 sm2 md2 lg2 xl2>
                <v-text-field
                  label="Marcatura CE"
                  v-model="verbale.certCE"
                  :rules="[rules.required]"
                  outlined
                  id="certCE"
                ></v-text-field>
              </v-col>
            </v-flex>
            <v-flex d-flex xs12 sm12 md12 lg12 xl12>
              <v-col xs6 sm6 md6 lg6 xl6>
                <v-text-field
                  outlined
                  label="Portata"
                  v-model="verbale.portata"
                  :rules="[rules.required]"
                  id="portata"
                ></v-text-field>
              </v-col>
              <v-col xs2 sm2 md2 lg2 xl2>
                <v-text-field
                  outlined
                  label="Ubicazione/Reparto"
                  v-model="verbale.ubicazioneReparto"
                  :rules="[rules.required]"
                  id="ubicazioneReparto"
                ></v-text-field>
              </v-col>
              <v-col xs2 sm2 md2 lg2 xl2>
                <v-text-field
                  label="Sigla Interna (ID)"
                  v-model="verbale.siglaInterna"
                  :rules="[rules.required]"
                  outlined
                  id="siglaInterna"
                ></v-text-field>
              </v-col>
            </v-flex>
            <v-flex d-flex xs12 sm12 md12 lg12 xl12>
              <v-col xs4 sm4 md4 lg4 xl4>
                <v-text-field
                  label="Costruttore"
                  outlined
                  v-model="verbale.marca"
                  :rules="[rules.required]"
                  id="costruttore"
                ></v-text-field>
              </v-col>
              <v-col xs4 sm4 md4 lg4 xl4>
                <v-text-field
                  label="Modello"
                  outlined
                  v-model="verbale.modello"
                  :rules="[rules.required]"
                  id="modello"
                ></v-text-field>
              </v-col>
              <v-col xs4 sm4 md4 lg4 xl4>
                <v-text-field
                  label="Numero di Fabbrica"
                  outlined
                  v-model="verbale.nFab"
                  :rules="[rules.required]"
                  id="nfab"
                ></v-text-field>
              </v-col>
            </v-flex>
            <v-flex d-flex xs12 sm12 md12 lg12 xl12>
              <v-col>
                <v-text-field
                  label="Installato/utilizzato nel cantiere/stabilimento della ditta"
                  outlined
                  v-model="verbale.ditta"
                  :rules="[rules.required]"
                  id="ditta"
                ></v-text-field>
              </v-col>
            </v-flex>
            <v-flex d-flex xs12 sm12 md12 lg12 xl12>
              <v-col xs4 sm4 md4 lg4 xl4>
                <v-text-field
                  label="Comune"
                  outlined
                  v-model="verbale.comuneDitta"
                  :rules="[rules.required]"
                  id="comuneDitta"
                ></v-text-field>
              </v-col>
              <v-col xs4 sm4 md4 lg4 xl4>
                <v-text-field
                  label="Via"
                  outlined
                  v-model="verbale.viaDitta"
                  :rules="[rules.required]"
                  id="viaDitta"
                ></v-text-field>
              </v-col>
              <!--<v-col xs1 sm1 md1 lg1 xl1>
                <v-text-field
                  label="Numero civico"
                  outlined
                  v-model="verbale.numeroCivicoDitta"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>-->
            </v-flex>
          </v-stepper-content>
          <!--Fine prima scheda-->

          <!-- Seconda scheda - Rilevazioni -->
          <v-stepper-content step="2">
            <v-flex xs12 sm12 md12 lg12 xl12>
              <v-textarea
                label="Testo"
                outlined
                v-model="verbale.testo"
                :rules="[rules.required]"
                id="testo"
              ></v-textarea>
            </v-flex>
            <v-flex xs12 sm12 md12 lg12 xl12>
              <v-textarea
                label="Verifica di Funzionamento"
                outlined
                v-model="verbale.verificaFunzionamento"
                :rules="[rules.required]"
                id="verificaFunzionamento"
              ></v-textarea>
            </v-flex>
            <v-flex xs12 sm12 md12 lg12 xl12>
              <v-textarea
                label="Verifica Strutturale"
                outlined
                v-model="verbale.verificaStrutturale"
                :rules="[rules.required]"
                id="verificaStrutturale"
              ></v-textarea>
            </v-flex>
            <v-flex xs12 sm12 md12 lg12 xl12>
              <v-textarea
                label="Note"
                outlined
                v-model="verbale.note"
                :rules="[rules.required]"
                id="note"
              ></v-textarea>
            </v-flex>
          </v-stepper-content>
          <!-- Fine seconda scheda-->

          <!--Terza scheda - Esito-->
          <v-stepper-content step="3">
            <v-flex xs12 sm12 md12 lg12 xl12 class="space-up">
              <v-select
                label="Esito"
                id="esito"
                outlined
                v-model="verbale.esito"
                :items="listaEsiti"
                item-text="text"
                item-value="value"
                :rules="[rules.select]"
              ></v-select>
            </v-flex>
            <v-flex d-flex xs12 sm12 md12 lg12 xl12>
              <v-col xs6 sm6 md6 lg6 xl6>
                <DataPicker
                  label="Data prossima verifica"
                  v-model="verbale.dataProssimaVerifica"
                  id="dataProssimaVerifica"
                ></DataPicker>
              </v-col>
              <v-col xs6 sm6 md6 lg6 xl6>
                <v-text-field
                  label="Luogo"
                  outlined
                  v-model="verbale.luogoVerifica"
                  :rules="[rules.required]"
                  id="luogoVerifica"
                ></v-text-field>
              </v-col>
            </v-flex>
            <v-flex d-flex xs12 sm12 md12 lg12 xl12>
              <v-checkbox
                v-model="verbale.updateScad"
                label="Aggiorna dati sullo scadenziario"
                id="updateScad"
              ></v-checkbox>
            </v-flex>
          </v-stepper-content>
          <!--Fine terza scheda-->

          <!--Quarta scheda - Firme-->
          <v-stepper-content step="4">
            <v-flex
              xs12
              sm12
              md12
              lg12
              xl12
              class="space-up"
              v-if="numeroScheda == 4"
            >
              <h4>Firma cliente</h4>
              <VueSignaturePad
                style="border: 1px solid #6c757d"
                id="firmaDatore"
                width="351"
                height="90"
                ref="firmaDatore"
              />
            </v-flex>
            <br />
            <v-flex d-flex xs12 sm12 md12 lg12 xl12>
              <v-btn color="error" @click="$refs.firmaDatore.clearSignature()"
                >Cancella firma</v-btn
              >
            </v-flex>
            <br />
            <v-flex d-flex xs12 sm12 md12 lg12 xl12>
              <v-checkbox
                v-show="false"
                label="Firma verificatore in affiancamento"
                v-model="secondaFirma"
                @change="resetSecondoVerificatore"
                id="secondaFirma"
              ></v-checkbox>
            </v-flex>
            <v-flex d-flex xs12 sm12 md12 lg12 xl12 v-if="secondaFirma">
              <v-select
                label="Firma verificatore"
                outlined
                :items="listaVerificatori"
                item-text="name"
                item-value="ad_user_id"
                v-model="verbale.ad_usersecondoverificatore_id"
                no-data-text
                :rules="[rules.select]"
              ></v-select>
            </v-flex>
            <v-flex d-flex xs12 sm12 md12 lg12 xl12 v-if="secondaFirma">
              <v-select
                label="Firma verificatore"
                outlined
                :items="listaVerificatori"
                item-text="name"
                item-value="ad_user_id"
                v-model="verbale.ad_userterzoverificatore_id"
                no-data-text
                clearable
              ></v-select>
            </v-flex>
            <br />
          </v-stepper-content>
          <!--Fine quarta scheda-->
        </v-stepper-items>
      </v-form>
    </v-stepper>
    <v-card mr-5>
      <v-footer fixed>
        <v-container>
          <v-flex d-flex>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="schedaPrecedente"
              :disabled="numeroScheda <= 1"
              id="schedaPrecedente"
              >Indietro</v-btn
            >
            <v-btn
              v-if="numeroScheda < schedeTotali"
              color="primary"
              @click="schedaSuccessiva"
              id="schedaSuccessiva"
              >Avanti</v-btn
            >
            <v-btn
              v-else
              color="primary"
              @click="salvaVerbale"
              :disabled="saveDisabled || !form"
              id="salvaVerbale"
              >Salva</v-btn
            >
          </v-flex>
        </v-container>
      </v-footer>
    </v-card>
    <ConfirmDialog ref="confirm"></ConfirmDialog>
    <v-dialog v-model="dialogRiepilogo" width="60%" persistent>
      <v-card>
        <v-card-title>Riepilogo</v-card-title>
        <v-card-text>
          <v-simple-table>
            <thead>
              <th>Nome verbale</th>
              <th>Data verbale</th>
              <th>Data prossima verifica</th>
            </thead>
            <tbody>
              <tr>
                <td>{{ riepilogo.nomeVerbale }}</td>
                <td>{{ riepilogo.dataVerifica }}</td>
                <td>{{ riepilogo.dataProxVer }}</td>
              </tr>
            </tbody>
          </v-simple-table>
          <br>
          <v-alert type="warning" text v-if="isBadPayer" dense>
            Il cliente è un cattivo pagatore
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="home" text>Torna alla home</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
/*eslint-disable  */
import DataPicker from "../components/DataPicker";
import ConfirmDialog from "../components/ConfirmDialog";

export default {
  components: {
    DataPicker,
    ConfirmDialog,
  },
  data() {
    return {
      numeroScheda: 1,
      schedeTotali: 4,
      verbale: {
        fct_vertecnicospsc_id: 0,
        dataVerifica: new Date().toISOString().substr(0, 10),
        tipoVerifica: 0,
        dataProssimaVerifica: new Date().toISOString().substr(0, 10),
        tipologiaMacchinario: 0,
        tipoMacchinario: "",
        anno: "",
        matricola: "",
        certCE: "SI",
        marca: "",
        modello: "",
        ditta: "",
        nFab: "",
        comuneDitta: "",
        viaDitta: "",
        note: "Verifica eseguita su richiesta dell’azienda per la sicurezza in generale, ai sensi dell’Art. 71 comma 8, lettera b) e c)\ndel D.lgs. 81/08.\nSe non ancora effettuata, si consiglia di procedere alla denuncia di messa in servizio dell’impianto secondo D.M.\n11/04/2011 alla sede territorialmente competente dell’INAIL (settore ex ISPESL).",
        esito:
          "Nelle attuali condizioni di conservazione la macchina risulta in ordine ed in grado di operare in condizioni di sicurezza.",
        luogoVerifica: "",
        c_bpartner_id: 0,
        fct_scadverifiche_id: 0,
        c_orderline_id: 0,
        ad_userverificatore_id: 0,
        ad_usersecondoverificatore_id: 0,
        ad_userterzoverificatore_id: 0,
        motivoRifiuto: "",
        updateScad: true,
        verificaStrutturale:
          "Eseguita verifica visiva dell’intera struttura. Non sono state rilevate deformazioni, discontinuità, cricche o quant’altro possa pregiudicare la stabilità della macchina in fase di lavoro.",
        verificaFunzionamento:
          "Controllato il corretto funzionamento dei comandi e dei dispositivi di sicurezza. Eseguita prova di carico. In ordine, non si sono riscontrate anomalie e/o malfunzionamenti.",
      },
      tipoVerifica: [],
      tipologiaMacchinario: [],
      secondaFirma: false,
      listaVerificatori: [],
      firmaVerificatore: "",
      saveDisabled: false,
      firmaDatore: "",
      rules: {
        required: (value) => (!!value ? true : "Campo richiesto!"),
        select: (value) => value != 0,
      },
      form: null,
      riepilogo: {},
      dialogRiepilogo: false,
      isBadPayer:false,
      listaEsiti: [
        {
          text: "Positivo",
          value:
            "Nelle attuali condizioni d’impianto e di esercizio, l’attrezzatura/impianto <u>RISULTA</u> in ordine alle condizioni di sicurezza.",
        },
        {
          text: "Negativo",
          value:
            "Nelle attuali condizioni d’impianto e di esercizio, l’attrezzatura/impianto <u>NON RISULTA</u> in ordine alle condizioni di sicurezza.",
        },
      ],
    };
  },
  methods: {
    schedaPrecedente() {
      if (this.numeroScheda > 1) {
        this.numeroScheda = parseInt(this.numeroScheda) - 1;
      }
    },
    schedaSuccessiva() {
      if (this.numeroScheda < this.schedeTotali) {
        this.numeroScheda = parseInt(this.numeroScheda) + 1;
      }
    },
    tornaAllaHome() {
      this.$router.push({ path: "/" });
    },
    resetSecondoVerificatore() {
      if (!this.secondaFirma) {
        this.verbale.ad_usersecondoverificatore_id = 0;
        this.verbale.ad_userterzoverificatore_id = 0;
      }
    },
    toISOStringUTC(datestr) {
      var tzoffset = new Date().getTimezoneOffset() * 60000;
      return new Date(new Date(datestr) - tzoffset).toISOString().slice(0, -1);
    },
    salvaVerbale() {
      if (!this.$refs.validForm.validate()) return;
      this.saveDisabled = true;
      this.$store.commit("setProgressMessage", "Salvaggio verbale");
      this.$store.commit("setProgressLoading", true);
      let data = Object.assign({}, this.verbale);
      if (this.$refs.firmaDatore.isEmpty()) {
        data.firmaTitolareBase64 = null;
      } else {
        data.firmaTitolareBase64 = this.$refs.firmaDatore
          .saveSignature()
          .data.split(",")[1];
      }
      data.dataVerifica = this.toISOStringUTC(data.dataVerifica);
      data.dataProssimaVerifica = this.toISOStringUTC(
        data.dataProssimaVerifica
      );

      let parameters = {};
      parameters.data = data;
      parameters.idempiereRestPath =
        "seritec/inserisciAggiornaVerbaleTecnicoSPSC";
      console.log(data);
      this.$store.dispatch("callIdempiereRest", parameters).then(
        (response) => {
          if (response.data.fct_vertecnicospsc_id != 0) {
            this.$store.commit("setProgressLoading", false);
            this.$store.commit("setProgressMessage", "Salvataggio riuscito");
            var that = this;
            let item = response.data;
            let parameters = {};
            parameters.idempiereRestPath = "print/addPrintJob";
            let data = {};
            data.parameters = "{id:" + item.fct_vertecnicospsc_id + "}";
            data.nomeStampa = "Verbale Tecnico SPSC";
            parameters.data = data;

            var that = this;
            this.riepilogo.nomeVerbale = response.data.nomeVerbale;
            this.riepilogo.dataVerifica = response.data.dataVerbale;
            this.riepilogo.dataProxVer = response.data.dataProxVer;
            this.dialogRiepilogo = true;

            setTimeout(function () {
              that.$store.commit("setProgressMessage", null);
            }, 1000);

            this.$store
              .dispatch("callIdempiereRest", parameters)
              .then((response) => {
                console.log(response);
                if (response.data.hasOwnProperty("file")) {
                  const linkSource =
                    "data:application/pdf;base64," + response.data.file;
                  const downloadLink = document.createElement("a");
                  const fileName = item.nomeVerbale + ".pdf";

                  downloadLink.href = linkSource;
                  downloadLink.download = fileName;
                  downloadLink.click();
                  setTimeout(function () {
                    that.$store.commit("setBozzaSP", null);
                    that.$store.commit("setProgressMessage", null);
                    that.$store.commit("setProgressLoading", true);
                    that.saveDisabled = false;
                    that.$store.commit("setBozzaSP", null);
                  }, 5000);
                } else {
                  this.$store.commit("setProgressMessage", null);
                  var errorMsg = "Stampa non riuscita";
                  if (response.data.hasOwnProperty("message")) {
                    errorMsg = errorMsg + "\n" + response.data.message;
                  }
                  this.$store.commit("setErrorMessage", errorMsg);
                }
              });
          } else {
            this.$store.commit("setProgressMessage", null);
            var errorMsg = "Salvataggio non riuscito";
            if (response.data.hasOwnProperty("message")) {
              errorMsg = errorMsg + "\n" + response.data.message;
            }
            this.$store.commit("setErrorMessage", errorMsg);
          }
        },
        (error) => {
          this.$store.commit("setProgressMessage", null);
          var errorMsg = "Salvataggio non riuscito";
          errorMsg = errorMsg + "\n" + error;
          this.$store.commit("setErrorMessage", errorMsg);
        }
      );
    },
    home() {
      this.$store.commit("setBozzaSP", null);
      this.$store.commit("setProgressMessage", null);
      this.$store.commit("setProgressLoading", true);
      this.saveDisabled = false;
      this.$store.commit("setBozzaSP", null);
      this.$router.push({ path: "/" });
    },
  },
  mounted: async function () {
    this.$store.commit("setProgressMessage", "Caricamento dati macchina...");
    this.$vuetify.lang.current = "it";
    let data = {};
    data.type = "SPTipologiaMacchinario";
    let parameters = {};
    parameters.data = data;
    parameters.idempiereRestPath = "vecos/getVerbalOptions";
    await this.$store
      .dispatch("callIdempiereRest", parameters)
      .then((response) => {
        this.tipologiaMacchinario = response.data.opzioni;
      });
    parameters.data.type = "tipoVerificaSP";
    await this.$store
      .dispatch("callIdempiereRest", parameters)
      .then((response) => {
        this.tipoVerifica = response.data.opzioni;
      });
    parameters.data = {};
    parameters.idempiereRestPath = "seritec/getListaVerificatori";
    await this.$store
      .dispatch("callIdempiereRest", parameters)
      .then((response) => {
        this.listaVerificatori = response.data.listaVerificatori;
      });
    this.isBadPayer = this.$store.state.datiVerbale.isBadPayer;
    if (
      this.$store.state.datiVerbale.type == "C" ||
      this.$store.state.datiVerbale.type == "M"
    ) {
      data = {};
      data.fct_vertecnicospsc_id = this.$store.state.datiVerbale.fct_verspsc_id;
      parameters = {};
      parameters.data = data;
      parameters.idempiereRestPath = "seritec/getVerbaleTecnicoSPSC";
      await this.$store
        .dispatch("callIdempiereRest", parameters)
        .then((response) => {
          this.verbale = Object.assign({}, response.data.verbale);
          this.firmaDatore = this.verbale.firmaDatore;
          delete this.verbale.firmaDatore;
          this.verbale.updateScad = true;
          if (this.$store.state.datiVerbale.type == "C") {
            this.verbale.fct_vertecnicospsc_id = 0;
            this.verbale.ad_usersecondoverificatore_id = 0;
            this.verbale.ad_userterzoverificatore_id = 0;
            this.firmaDatore = "";
            this.verbale.dataVerifica = new Date().toISOString().substr(0, 10);
            let dataProxVer = new Date();
            dataProxVer.setFullYear(dataProxVer.getFullYear() + 1);
            this.verbale.dataProssimaVerifica = dataProxVer
              .toISOString()
              .substr(0, 10);
            this.verbale.c_orderline_id = parseInt(
              this.$store.state.datiVerbale.c_orderline_id
            );
            this.verbale.fct_scadverifiche_id = parseInt(
              this.$store.state.datiVerbale.fct_scadverifiche_id
            );
            this.verbale.c_bpartner_id = parseInt(
              this.$store.state.datiVerbale.c_bpartner_id
            );
          } else {
            this.verbale.updateScad = false;
            this.verbale.fct_vertecnicospsc_id = parseInt(
              this.$store.state.datiVerbale.fct_verspsc_id
            );
            if (this.verbale.ad_usersecondoverificatore_id != 0) {
              this.secondaFirma = true;
            }
          }
        });
    } else {
      this.verbale.ad_userverificatore_id = this.$session.get("ad_user_id");
      data = {};
      data.fct_scadverifiche_id =
        this.$store.state.datiVerbale.fct_scadverifiche_id;
      parameters = {};
      parameters.data = data;
      parameters.idempiereRestPath = "vecos/getDatiMacchina";
      await this.$store
        .dispatch("callIdempiereRest", parameters)
        .then((response) => {
          let macchinario = response.data.macchinario;
          this.verbale.anno = macchinario.anno;
          this.verbale.certCE = macchinario.certCE;
          this.verbale.comuneDitta = macchinario.comuneDitta;
          this.verbale.ditta = macchinario.ditta;
          this.verbale.marca = macchinario.costruttore;
          this.verbale.matricola = macchinario.matricolaInail;
          this.verbale.modello = macchinario.modello;
          this.verbale.nFab = macchinario.nFab;
          //this.verbale.numeroCivicoDitta = macchinario.numeroCivicoDitta;
          this.verbale.viaDitta = macchinario.viaDitta;
          this.verbale.luogoVerifica = macchinario.comuneDitta;
          this.verbale.tipoMacchinario = macchinario.tipoAttrezzatura;
        });
      this.verbale.dataVerifica = new Date().toISOString().substr(0, 10);
      let dataProxVer = new Date();
      dataProxVer.setFullYear(dataProxVer.getFullYear() + 1);
      this.verbale.dataProssimaVerifica = dataProxVer
        .toISOString()
        .substr(0, 10);
    }

    this.verbale.fct_scadverifiche_id = parseInt(
      this.$store.state.datiVerbale.fct_scadverifiche_id
    );
    this.verbale.c_bpartner_id = parseInt(
      this.$store.state.datiVerbale.c_bpartner_id
    );
    this.verbale.c_orderline_id = parseInt(
      this.$store.state.datiVerbale.c_orderline_id
    );
    /*console.log(
      this.$store.state.bozzaSP.verbale.fct_verspsc_id +
        "   " +
        this.$store.state.datiVerbale.fct_verspsc_id
    );*/
    this.$store.commit("setProgressMessage", null);
    if (
      this.$store.state.bozzaSP != null &&
      this.$store.state.bozzaSP.verbale.fct_verspsc_id ==
        this.$store.state.datiVerbale.fct_verspsc_id &&
      this.$store.state.bozzaSP.verbale.fct_scadverifiche_id ==
        this.verbale.fct_scadverifiche_id &&
      this.$store.state.datiVerbale.type == this.$store.state.bozzaSP.type
    ) {
      this.$refs.confirm
        .open("Salvataggio", "Ripristinare il salvataggio automatico?", {
          color: "primary",
        })
        .then((confirm) => {
          if (confirm) {
            this.verbale = this.$store.state.bozzaSP.verbale;
          } else {
            this.$store.commit("setBozzaSP", null);
          }
          /*this.verbale.dataVerifica = new Date().toISOString().substr(0, 10);
          this.verbale.dataProssimaVerifica = new Date()
            .toISOString()
            .substr(0, 10);*/
        });
    } else {
      this.$store.commit("setBozzaSP", null);
    }

    var that = this;
    setInterval(() => {
      let bozza = {};
      bozza.verbale = that.verbale;
      bozza.type = that.$store.state.datiVerbale.type;
      that.$store.commit("setBozzaSP", bozza);
      console.log("salvataggio");
    }, 20000);
  },
  watch: {
    numeroScheda() {
      if (this.numeroScheda == this.schedeTotali && this.firmaDatore != "") {
        var that = this;
        setTimeout(function () {
          that.$refs.firmaDatore.fromDataURL(
            "data:image/png;base64," + that.firmaDatore
          );
        }, 10);
      }
    },
  },
  computed: {
    mostraTipoApparecchio: function () {
      if (this.tipologiaMacchinario.length < 1) return false;
      let macchinario = "";
      for (let element of this.tipologiaMacchinario) {
        if (element.id == this.verbale.tipologiaMacchinario) {
          macchinario = element.name;
        }
      }
      return macchinario == "Gru" || macchinario == "Altro";
    },
  },
};
</script>
<style>
.space-up {
  padding-top: 5px;
}
</style>